import Avatar from 'boring-avatars';
import Logo from './Logo';

export default function Team() {
    return (
        <main>

            <section className='outlinedContainer flexColumnCentered'>
            <Logo />
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Noah an Artist" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Noah</h2>
                    <h3>Artist</h3>
                </div>
            </section>


            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Josh Game Tester" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Josh</h2>
                    <h3>Game Tester</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Karim an Artist" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Karim</h2>
                    <h3>Artist</h3>
                </div>
            </section>


            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Carla a composer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Carla</h2>
                    <h3>Music composer / Sound designer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Huanyu an artist" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Huanyu</h2>
                    <h3>Artist</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Philipp a developer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Philipp</h2>
                    <h3>Developer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Nicollas a developer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Nicollas</h2>
                    <h3>Developer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="Rustam a developer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>Rustam</h2>
                    <h3>Developer</h3>
                </div>
            </section>

            <section className='outlinedContainer flexColumnCentered minHeight'>
                <div>
                    <Avatar name="GK3000 a producer" colors={["#59b390", "#f0ddaa", "#e47c5d", "#e32d40", "#152b3c"]} variant="beam" size={120} />
                    <h2>George</h2>
                    <h3>Producer</h3>
                </div>
            </section>




        </main>
    )
}
